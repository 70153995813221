import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import { Section } from './style'

type heroType = {
  setIsOpen: Function;
  setIsModalOpenAccount: Function;
  isMobile: boolean;
  oneLink: string;
}

const Hero = ({ setIsOpen, isMobile, setIsModalOpenAccount, oneLink }: heroType) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='d-flex align-items-center position-relative justify-content-md-between  bg-white py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 px-0'>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 fw-600'>
              Global Account Inter: sua conta internacional
            </h1>
            <p className='fs-14 lh-19 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--400'>
              Conta em dólar sem taxa de abertura e manutenção, investimentos, passagens aéreas e muito mais.
            </p>
            {
              isMobile ? (
                <>
                  <button
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none'
                    title='Quero ser cliente Inter'
                    onClick={() => {
                      setIsModalOpenAccount(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Global Account Inter: a sua conta internacional',
                        element_action: 'click button',
                        element_name: 'Quero ser cliente Inter',
                      })
                    }}
                  >
                    Quero ser cliente Inter
                  </button>

                  <a
                    href={oneLink}
                    target='_blank'
                    rel='noreferrer'
                    className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 text-none mt-3'
                    title='JJá sou cliente e quero ter a Conta Global'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Global Account Inter: a sua conta internacional',
                        element_action: 'click button',
                        element_name: 'Já sou cliente e quero ter a Conta Global',
                        redirect_url: oneLink,
                      })
                    }}
                  >
                    Já sou cliente e quero ter a Conta Global
                  </a>
                </>
              ) : (
                <>
                  <button
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-lg-4 mw-100'
                    onClick={() => {
                      setIsModalOpenAccount(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Global Account Inter: a sua conta internacional',
                        element_action: 'click button',
                        element_name: 'Quero ser cliente Inter',
                      })
                    }}
                  >
                    Quero ser cliente Inter
                  </button>

                  <button
                    className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 text-none mt-3 mw-100'
                    onClick={() => {
                      setIsOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Global Account Inter: a sua conta internacional',
                        element_action: 'click button',
                        element_name: 'Já sou cliente e quero ter a Conta Global',
                      })
                    }}
                  >
                    Já sou cliente e quero ter a Conta Global
                  </button>
                </>
              )
            }
          </div>

          <div className='col-12 col-md-6 col-xl-5 offset-xl-1 mb-4 mb-md-0 d-none d-md-block'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/gestao-de-cobranca-woman-suitcase/image.webp'
              altDescription='Super App Inter'
              arrayNumbers={[ 336, 336, 440, 545 ]}
              className='float-md-right'
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
